import mock from './../mock';
import {hsseYellow, hsseRed} from '@hsse/hsse-colors';

// Widget score types
const SCORE_PERCENTAGE = 1;
const SCORE_OUT_OF_FIVE = 2;
const SCORE_NUMBER = 3;
const SCORE_DRILLS = 4;

// Widget IDs
const WIDGET_SAM_DRILLS = 'SAM_Drills';
const WIDGET_SAM_QUESTIONS = 'SAM_Questions';
const WIDGET_RLA_HSSE = 'RLA_HSSE';
const WIDGET_RSAA_LTO = 'RSAA_LtO';
const WIDGET_RSAA_HSSE = 'RSAA_HSSE';
const WIDGET_FSC_DATA = 'FSC_Data';

const hsse = {
    stations: []
};

const hsseDashboardAppDB = {
    widgets : {
        stationData: {
            samDrills: [
                {
                    Score: 10
                }
            ],
            samQuestions: [
                {
                    Score: 2
                }
            ]
        },
        'samDrills'      : {
            'widgetID'    : WIDGET_SAM_DRILLS,
            'title'       : 'SAM',
            'subtitle'    : 'Drills',
            'animates'    : false,
            'score'       : 0,
            'scoreLegend' : 'Drills',
            'scoreType'   : SCORE_DRILLS,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'textColor'   : hsseRed[500],
            'backgroundColor': hsseYellow[500],
            'titleImage'  : 'assets/images/hsse/ghw.png',
        },
        'samQuestions'    : {
            'widgetID'    : WIDGET_SAM_QUESTIONS,
            'title'       : 'SAM',
            'subtitle'    : 'Questions',
            'animates'    : true,
            'score'       : 0,
            'scoreLegend' : 'Score',
            'scoreType'   : SCORE_OUT_OF_FIVE,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'threshold'   : 4,
            'backgroundColor': hsseYellow[500],
            'textColor'   : hsseRed[500],
            'titleImage'  : 'assets/images/hsse/ghw.png',
            'showButton'  : false,
            'buttonColor' : hsseRed[500],
        },
        'rlaModules'      : {
            'widgetID'    : WIDGET_RLA_HSSE,
            'title'       : 'RLA',
            'subtitle'    : 'HSSE Modules',
            'animates'    : true,
            'score'       : 0,
            'scoreLegend' : 'Score',
            'scoreType'   : SCORE_PERCENTAGE,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'threshold'   : 95,
            'textColor'   : hsseYellow[500],
            'backgroundColor': hsseRed[500],
        },
        'rsaaLtO'         : {
            'widgetID'    : WIDGET_RSAA_LTO,
            'title'       : 'RSAA',
            'subtitle'    : 'LtO',
            'animates'    : true,
            'score'       : 0,
            'scoreLegend' : 'Non-Compliant',
            'scoreType'   : SCORE_NUMBER,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'backgroundColor': 'white',
            'textColor'   : hsseRed[500],
            'titleImage'  : 'assets/images/hsse/shell-logo.png',
        },
        'rsaaHSSE'        : {
            'widgetID'    : WIDGET_RSAA_HSSE,
            'title'       : 'RSAA',
            'subtitle'    : 'HSSE',
            'animates'    : true,
            'score'       : 0,
            'scoreLegend' : 'Non-Compliant',
            'scoreType'   : SCORE_NUMBER,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'backgroundColor': 'white',
            'textColor'   : hsseRed[500],
            'titleImage'  : 'assets/images/hsse/shell-logo.png',
        },
        'fscData'         : {
            'widgetID'    : WIDGET_FSC_DATA,
            'title'       : 'FSC',
            'subtitle'    : 'Data',
            'animates'    : true,
            'score'       : 0,
            'scoreType'   : SCORE_PERCENTAGE,
            'scoreColor'  : hsseRed[500],
            'scoreColor2' : '#00b135',
            'threshold'   : 85,
            'backgroundColor': 'white',
            'textColor'   : hsseRed[500],
            'titleImage'  : 'assets/images/hsse/fsc.png',
        },
    }
};

mock.onGet('/hsse/stations').reply((config) => {
    return [200, hsse.stations];
})

mock.onPost('/hsse/setStations').reply((request) => {
    const data = JSON.parse(request.data);
    hsse.stations = data.stationList
    return [200, hsse.stations];
});


mock.onGet('/api/hsse-dashboard-app/widgets').reply((config) => {
    return [200, hsseDashboardAppDB.widgets];
});

mock.onGet('/api/hsse-dashboard-app/projects').reply((config) => {
    return [200, hsseDashboardAppDB.projects];
});
