import * as Actions from '../actions/hsse.actions';

const initialState = {
    companies: [],
    stationList: [],
    selectedStation: null,
    summarySnapshots: [],       // list of summary snapshots available
    summaryStation: null,       // the station selected when requesting summary
    getUserData: true,          // flag indicates if getUserData needs to be called
                                // Used for circular progress indicator in UserMenu
    appSettings: {}             // Global app settings set by Admin user
};

const hsse = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_STATION_LIST:
        {
            return {
                ...state,
                stationList: action.payload,
                getUserData: action.payload === undefined || action.payload.length === 0
            }
        }
        case Actions.SET_STATION_LIST:
        {
            return {
                ...state,
                stationList: action.stationList
            }
        }
        case Actions.STATION_SELECTED:
        {
            return {
                ...state,
                selectedStation: action.selectedStation
            }
        }
        case Actions.SET_SUMMARY_STATION:
        {
            return {
                ...state,
                summaryStation: action.summaryStation
            }
        }
        case Actions.GET_SUMMARY_SNAPSHOTS:
        {
            return {
                ...state,
                summarySnapshots: action.payload.data
            }
        }
        case Actions.GET_USER_DATA:
        {
            return {
                ...state,
                companies: action.payload.user.Companies,
                summarySnapshots: action.payload.user.Snapshots,
                stationList: action.payload.stationList,
                getUserData: false
            }
        }
        case Actions.GET_APP_SETTINGS:
        {
            return {
                ...state,
                appSettings: action.payload
            }
        }
        case Actions.USER_LOGGED_OUT:
        {
            return initialState;
        }
        default:
        {
            return state;
        }
    }
};

export default hsse;
