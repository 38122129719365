import {HSSEDashboardAppConfig} from './dashboards/hsse/HSSEDashboardAppConfig';
import {ActionPlanAppConfig} from './action-plan/ActionPlanAppConfig';
import {AdminAppConfig} from './admin/AdminAppConfig';
// import {QuestionsAppConfig} from './questions/QuestionsAppConfig';

export const appsConfigs = [
    HSSEDashboardAppConfig,
    ActionPlanAppConfig,
    AdminAppConfig,
    // QuestionsAppConfig,
];
