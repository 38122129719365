import React from 'react'
import {Card, CardContent, Hidden, Typography} from '@material-ui/core';
import {darken} from '@material-ui/core/styles/colorManipulator';
import {FuseAnimate} from '@fuse';
import clsx from 'clsx';
import RLALoginTab from './tabs/RLALoginTab';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color     : theme.palette.primary.contrastText
    }
}));

function Login()
{
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0")}>

            <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">

                <FuseAnimate animation="transition.expandIn">
                    <img className="w-128 mb-32" src="assets/images/hsse/shell-logo.png" alt="logo"/>
                </FuseAnimate>

                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <Typography variant="h3" color="inherit" className="font-light hidden sm:flex">
                        Welcome to HSSE Dashboard
                    </Typography>
                </FuseAnimate>
                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <Typography variant="h6" color="inherit" className="font-light sm:hidden">
                        Welcome to HSSE Dashboard
                    </Typography>
                </FuseAnimate>

                <Hidden smDown>
                    <FuseAnimate delay={400}>
                        <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
                            This  dashboard gives you a quick overview of your stations performance in: Drills, SAM Questions, HSSE & Legislative (M) RLA training, RSAA Audit output for HSSE & LtO (Licence to Operate) questions and Food Safety scores.
                            All bar Food Safety are up to date as of midnight the night before. Food Safety is uploaded once a month for prior month and is for the most recent audit.
                        </Typography>
                    </FuseAnimate>
                </Hidden>
            </div>

            <FuseAnimate animation={{translateX: [0, '100%']}}>
                <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
                    <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">

                        <Typography variant="h6" className="text-center md:w-full mb-48">LOG IN TO YOUR ACCOUNT</Typography>

                        <RLALoginTab/>

                    </CardContent>
                </Card>
            </FuseAnimate>
        </div>
    )
}

export default Login;
