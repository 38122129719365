
export const TOGGLE_ACTION_PANEL = '[ACTION PANEL] TOGGLE ACTION PANEL';
export const OPEN_ACTION_PANEL = '[ACTION PANEL] OPEN ACTION PANEL';
export const CLOSE_ACTION_PANEL = '[ACTION PANEL] CLOSE ACTION PANEL';

export function toggleActionPlanPanel()
{
    return {
        type: TOGGLE_ACTION_PANEL
    }
}

export function openActionPlanPanel()
{
    return {
        type: OPEN_ACTION_PANEL
    }
}

export function closeActionPlanPanel()
{
    return (dispatch) => {
        // dispatch(Actions.removeSelectedContactId());
        // dispatch(Actions.removeChat());
        return dispatch({
            type: CLOSE_ACTION_PANEL
        })
    }
}
