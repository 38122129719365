import * as Actions from '../../actions/hsse/index';

const initialState = {
    summaryOpen: false
}

const dashboard = function (state = initialState, action) {
    switch (action.type) {
        case Actions.OPEN_SUMMARY: {
            return {
                ...state,
                summaryOpen: true
            }
        }
        case Actions.CLOSE_SUMMARY: {
            return {
                ...state,
                summaryOpen: false
            }
        }
        case Actions.TOGGLE_SUMMARY: {
            return {
                ...state,
                summaryOpen: !state.summaryOpen
            }
        }
        default: {
            return state
        }
    }
}

export default dashboard
