export const OPEN_SUMMARY = '[DASH] OPEN SUMMARY';
export const CLOSE_SUMMARY = '[DASH] CLOSE SUMMARY';
export const TOGGLE_SUMMARY = '[DASH] TOGGLE SUMMARY';

export function openSummary() {
    return {
        type: OPEN_SUMMARY
    }
}

export function closeSummary() {
    return {
        type: CLOSE_SUMMARY
    }
}

export function toggleSummary() {
    return {
        type: TOGGLE_SUMMARY
    }
}
