const hsseRed = {
    50: '#f9e7e7',
    100: '#f0c2c2',
    200: '#e69999',
    300: '#db7070',
    400: '#d45252',
    500: '#cc3333',
    600: '#c72e2e',
    700: '#c02727',
    800: '#b92020',
    900: '#ad1414',
    A100: '#ffe0e0',
    A200: '#ffadad',
    A400: '#ff7a7a',
    A700: '#ff6060'
};

export default hsseRed;

