import _ from '@lodash';

/**
 * Authorization Roles
 */
const authRoles = {
    admin    : ['admin'],
    ho       : ['admin','ho'],
    dm       : ['admin','ho','dm'],
    tm       : ['admin','ho','dm','tm'],
    retailer : ['admin','ho','dm','tm','retailer'],
    station  : ['admin','ho','dm','tm','retailer','station'],
    onlyGuest: []
};

export function isManager(user) {
    return _.includes(authRoles.tm, user.role);
}

export function isRetailer(user) {
    return _.includes(authRoles.retailer, user.role);
}

export default authRoles;
