import axios from 'axios';

export const GET_STATION_DATA = '[HSSE] GET STATION DATA';

export function getStationData(params) {
    const request = axios.get('/api/station/' + params.GSAP, {params});

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type        : GET_STATION_DATA,
                routeParams : params,
                payload     : response.data
            })
        });
}
