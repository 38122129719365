import {combineReducers} from 'redux';
import hsse from './hsse';
import fuse from './fuse';
import auth from 'app/auth/store/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        hsse,
        ...asyncReducers
    });

export default createReducer;
