const hsseDark = {
	50: '#EBEBEB',
	100: '#CDCECE',
	200: '#ACADAD',
	300: '#8B8C8C',
	400: '#727373',
	500: '#595A5A',
	600: '#515252',
	700: '#484848',
	800: '#3E3F3F',
	900: '#2E2E2E',
	A100: '#F28E8E',
	A200: '#ED6060',
	A400: '#FF1B1B',
	A700: '#FF0202'
};

export default hsseDark;
