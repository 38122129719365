const hsseYellow = {
	50  : '#fff8e1',
	100 : '#ffecb3',
	200 : '#ffe082',
	300 : '#ffd54f',
	400 : '#ffca28',
	500 : '#ffc107',
	600 : '#ffb300',
	700 : '#ffa000',
	800 : '#ff8f00',
	900 : '#ff6f00',
	A100: '#ffe57f',
	A200: '#ffd740',
	A400: '#ffc400',
	A700: '#ffab00'
};

export default hsseYellow;

