import axios from 'axios';
import rlaService from 'app/services/rlaService';

export const GET_STATION_LIST = '[HSSE] SET STATION LIST';
export const SET_STATION_LIST = '[HSSE] SET STATION LIST';
export const STATION_SELECTED = '[HSSE] STATION SELECTED';
export const SET_SUMMARY_STATION = '[HSSE] SET SUMMARY STATION';
export const GET_SUMMARY_SNAPSHOTS = '[HSSE] GET SUMMARY SNAPSHOTS';
export const GET_USER_DATA = '[HSSE] GET USER DATA';
export const GET_APP_SETTINGS = '[HSSE] GET APP SETTINGS';
export const USER_LOGGED_OUT = '[HSSE] USER LOGGED OUT';

export function getStationList()
{
    return (dispatch, getState) => {

        const user = getState().auth.user
        const request = axios.get('/hsse/stations')

        request.then((response) => {
            dispatch({
                type: GET_STATION_LIST,
                payload: response.data,
            })

            // response.data is hsse.stations array
            if (response.data === undefined ||
                response.data.length === 0) {
                dispatch(getUserData(user))
            }
        });
    }
}

export function setStationList(stationList)
{
    return (dispatch) => {
        const request = axios.post('/hsse/setStations', {
            stationList
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_STATION_LIST,
                    stationList: stationList
                })
            ])
        );
    }
}

export function setSelectedStation(station)
{
    return {
        type: STATION_SELECTED,
        selectedStation: station
    }
}

export function setSummaryStation(station)
{
    return {
        type: SET_SUMMARY_STATION,
        summaryStation: station
    }
}

function getStationListFromCompanyList(companies) {
    var stations = companies.filter((co) => co.Type === 'Site');
    stations.sort((a, b) => {
        return a.Name.localeCompare(b.Name);
    });
    return stations;
}

export function getUserData(user)
{
    const accessToken = rlaService.getAccessToken();
    const request = axios.post('/api/user/', {
        access_token: accessToken,
        role: user.role
    });
    return (dispatch) =>
        request.then((response) => {
                response.data.stationList = getStationListFromCompanyList(response.data.user.Companies)
                Promise.all([
                    dispatch({
                        type: GET_USER_DATA,
                        payload: response.data
                    }),
                    dispatch(setStationList(response.data.stationList))
                ])
            }
        );
}

export function getAppSettings() {
    const request = axios.get('/api/settings/');

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type: GET_APP_SETTINGS,
                payload: response.data
            })
        });
}
