import axios from 'axios';
import rlaService from 'app/services/rlaService';
import * as FuseActions from 'app/store/actions';

export const GET_ACTION_ITEMS = '[ACTIONPLAN APP] GET ITEMS';
export const OPEN_NEW_ACTION_ITEM_DIALOG = '[ACTIONPLAN APP] OPEN NEW ACTION ITEM DIALOG';
export const CLOSE_NEW_ACTION_ITEM_DIALOG = '[ACTIONPLAN APP] CLOSE NEW ACTION ITEM DIALOG';
export const OPEN_EDIT_ACTION_ITEM_DIALOG = '[ACTIONPLAN APP] OPEN EDIT ACTION ITEM DIALOG';
export const CLOSE_EDIT_ACTION_ITEM_DIALOG = '[ACTIONPLAN APP] CLOSE EDIT ACTION ITEM DIALOG';
export const OPEN_ACTION_ITEM_CLOSE_DIALOG = '[ACTIONPLAN APP] OPEN ACTION ITEM CLOSE DIALOG';
export const CLOSE_ACTION_ITEM_CLOSE_DIALOG = '[ACTIONPLAN APP] CLOSE ACTION ITEM CLOSE DIALOG';
export const UPDATE_ACTION_ITEMS = '[ACTIONPLAN APP] UPDATE ACTION ITEMS';
export const ADD_ACTION_ITEM = '[ACTIONPLAN APP] ADD ACTION ITEM';
export const UPDATE_ACTION_ITEM = '[ACTIONPLAN APP] UPDATE ACTION ITEM';
export const DELETE_ACTION_ITEM = '[ACTIONPLAN APP] DELETE ACTION ITEM';
export const CLOSE_ACTION_PLAN_ITEM = '[ACTIONPLAN APP] CLOSE ACTION PLAN ITEM';
export const USER_LOGGED_OUT = '[ACTIONPLAN APP] USER LOGGED OUT';

export function getItems(stationID)
{
    const request = axios.get(`/api/action-plan/${stationID}`);

    return (dispatch) =>
        request.then((response) => {
            dispatch({
                type        : GET_ACTION_ITEMS,
                payload     : response.data
            })
        });
}


export function openNewActionItemDialog(item)
{
    return {
        type: OPEN_NEW_ACTION_ITEM_DIALOG,
        item
    }
}

export function closeNewActionItemDialog()
{
    return {
        type: CLOSE_NEW_ACTION_ITEM_DIALOG
    }
}

export function openEditActionItemDialog(data)
{
    return {
        type: OPEN_EDIT_ACTION_ITEM_DIALOG,
        data
    }
}

export function closeEditActionItemDialog()
{
    return {
        type: CLOSE_EDIT_ACTION_ITEM_DIALOG
    }
}

export function openActionItemCloseDialog(data)
{
    return {
        type: OPEN_ACTION_ITEM_CLOSE_DIALOG,
        data
    }
}

export function closeActionItemCloseDialog()
{
    return {
        type: CLOSE_ACTION_ITEM_CLOSE_DIALOG
    }
}

export function updateActionItems()
{
    return (dispatch, getState) => {
        const {routeParams} = getState().actionPlanApp.items;
        // const {user} = getState().auth;
        const {hsse} = getState().auth;

        const stationID = hsse.selectedStation.GUID
        // const username = user.data.displayName;
        const request = axios.get(`/api/action-plan/${stationID}`, {
            params: routeParams
        });

        return request.then((response) =>
            dispatch({
                type    : UPDATE_ACTION_ITEMS,
                payload : response.data
            })
        );
    }
}

export function closeActionPlanItem(item)
{
    return (dispatch, getState) => {
        const accessToken = rlaService.getAccessToken();
        const request = axios.post(`/api/action-plan/close`, { item: item, access_token: accessToken });

        return request.then((response) => (
                Promise.all([
                    dispatch({
                        type: CLOSE_ACTION_PLAN_ITEM,
                        payload: response.data
                    })
                ]).then(() => dispatch(updateActionItems()))
            )
        );
    }
}

export function addActionItem(item)
{
    const accessToken = rlaService.getAccessToken();
    const request = axios.post('/api/action-plan/create', { item: item, access_token: accessToken });

    return (dispatch) =>
        request.then((response) => (
                Promise.all([
                    dispatch({
                        type: ADD_ACTION_ITEM
                    })
                ]).then(() => {
                    dispatch(closeNewActionItemDialog());
                    dispatch(updateActionItems());
                    dispatch(FuseActions.showMessage({
                        message: response.data.message,
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }));
                })
            )
        ).catch(err => {
            // console.warn('addActionItem error: ' + err)
            dispatch(FuseActions.showMessage({
                message: "Action Plan error: " + err.response.data.error,
                variant: 'error',
                anchorOrigin: {
                    vertical  : 'center',
                     horizontal: 'center'
                }
            }))
        });
}

export function updateActionItem(item)
{
    const request = axios.post('/api/action-plan/update', item);

    return (dispatch) =>
        request.then((response) => (
                Promise.all([
                    dispatch({
                        type: UPDATE_ACTION_ITEM,
                        payload: response.data
                    })
                ]).then(() => dispatch(updateActionItems()))
            )
        );
}

export function deleteActionItem(itemId)
{
    const request = axios.post(`/api/action-plan/delete/${itemId}`, itemId);

    return (dispatch) =>
        request.then((response) => (
                Promise.all([
                    dispatch({
                        type: DELETE_ACTION_ITEM,
                        payload: response.data
                    })
                ]).then(() => dispatch(updateActionItems()))
            )
        );
}
