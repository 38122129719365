import React from 'react';
import {authRoles} from 'app/auth';

export const AdminAppConfig = {
    settings: {
        layout: {}
    },
    auth    : authRoles.admin,
    routes  : [
        {
            path     : '/apps/admin/importer',
            component: React.lazy(() => import('./importer/Importer'))
        },
        {
            path     : '/apps/admin/snapshots',
            component: React.lazy(() => import('./snapshots/Snapshots'))
        },
        {
            path     : '/apps/admin/settings',
            component: React.lazy(() => import('./settings/Settings'))
        },

        // {
        //     path     : '/apps/admin/manager',
        //     component: React.lazy(() => import('./manager/Manager'))
        // },
        {
            path     : '/apps/admin/',
            component: React.lazy(() => import('./AdminApp'))
        }
    ]
};
