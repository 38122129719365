import {setUserData} from './user.actions';
import rlaService from 'app/services/rlaService';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({email, password})
{
    return (dispatch) =>
        rlaService.signInWithEmailAndPassword(email, password)
            .then((user) => {
                   dispatch(setUserData(user));
                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}
