import {combineReducers} from 'redux';
import user from './user.reducer';
import login from './login.reducer';
import hsse from './hsse.reducer';

const authReducers = combineReducers({
    user,
    login,
    hsse
});

export default authReducers;
